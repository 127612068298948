import { createRouter, createWebHistory } from '@ionic/vue-router';
import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import Login from '@/views/Login.vue';

import store from '@/store';
import { User } from '@/store/modules/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/Terms.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('@/views/Forget.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/manage',
    name: 'Manage',
    component: () => import('@/views/Manage.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/finalapro',
    name: 'FinalApro',
    component: () => import('@/views/FinalApro.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/User.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/crdmday',   // 운송사 일별 운반현황
    name: 'CrdmDay',
    component: () => import('@/views/CrdmDay.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/crdmmonth',   // 운송사 월별 운반현황
    name: 'CrdmMonth',
    component: () => import('@/views/CrdmMonth.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/crcs',   // 운송사 운반비현황
    name: 'Crcs',
    component: () => import('@/views/Crcs.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/outtt',   // 출하현황집계
    name: 'Outtt',
    component: () => import('@/views/Outtt.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/brnews',   // 운송사 영업속보
    name: 'Brnews',
    component: () => import('@/views/Brnews.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/rtss',
    name: 'Rtss',
    component: () => import('@/views/Rtss.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/rtts',
    name: 'Rtts',
    component: () => import('@/views/Rtts.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/chulhaday',    // 거래처 일별 출하현황
    name: 'ChulhaDay',
    component: () => import('@/views/ChulhaDay.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/chulhamonth',    // 거래처 월별 출하현황
    name: 'ChulhaMonth',
    component: () => import('@/views/ChulhaMonth.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/accsum',    // 거래처별 출하집계
    name: 'AccSum',
    component: () => import('@/views/AccSum.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/chulhaassign',    // 출하배정현황
    name: 'ChulhaAssign',
    component: () => import('@/views/ChulhaAssign.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/chulhaassignmod',    // 출하배정수정
    name: 'ChulhaAssignMod',
    component: () => import('@/views/ChulhaAssignMod.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/chulhaassignnew',    // 출하신규배정
    name: 'ChulhaAssignNew',
    component: () => import('@/views/ChulhaAssignNew.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/close',
    name: 'Close',
    component: () => import('@/views/Close.vue'),
    meta: { requiredAuth: true }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/home'
  }
];

function guard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  data: User
) {
  if (to.meta && to.meta.requiredAuth) {
    if (data && data.id) {
      return next();
    }
    return next({ path: '/login' });
  } else {
    if (data && data.id) {
      return next({ path: '/home' });
    }

    return next();
  }
}

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL)
});

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    let data: User;
    await store.dispatch('auth/loadTokenFromStorage').then(() => {
      data = store.getters['auth/getData'];
      return guard(to, from, next, data);
    }),
      (err: any) => {
        console.log(err);
        return guard(to, from, next, data);
      };
  }
);

export default router;
