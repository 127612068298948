import { jwtDecrypt } from '@/utils/jwtDecrypt';
import { Storage } from '@capacitor/storage';
import { $api } from '@/api';
import { loadingController } from '@ionic/vue';
import { toast } from '@/utils/toast';
import { Loading } from '@/utils/loading';
import router from '@/router/index';

type loginStatus = 'success' | 'failed';
type login = {
  id: string;
  pw: string;
};
export interface User {
  login: loginStatus;
  id: string;
  name: string;
  access: {
    token: string;
    exp: string;
  };
  auth: string;
}

const state = () => ({
  user: {
    login: 'failed',
    id: '',
    sabn: '',
    name: '',
    access: {
      token: '',
      exp: ''
    },
    auth: ''
  }
});

const getters = {
  getData(state: { user: User }): User {
    return state.user;
  }
};

const actions = {
  async login({ commit }: any, payload: login) {
    const loading = new Loading(loadingController);

    loading.present('로그인중...');
    
    await $api('/auth', 'POST', payload)
      .then(async res => {
        const token = res.headers['authorization'];
        await Storage.set({
          key: 'access_token',
          value: token
        });
        commit('saveToken', token);   
        loading.dismiss();
        router.push('/home');
      })
      .catch(err => {
        commit('saveLoginStatus', 'failed');
        loading.dismiss();
        if (err.response.status == 404) {
          alert(err.response.data.message);
        } else if (err.response.status == 500) {
          toast(
            '내부서버의 오류로 인해 현재 서비스의 상태가 원할하지 않습니다. 시스템 관리자에게 문의하시기 바랍니다.',
            5000
          );
        }
      });
  },
  async logout({ commit }: any) {
    await Storage.remove({ key: 'access_token' });
    sessionStorage.removeItem('itnmlist');
    commit('resetData');
    commit('saveLoginStatus', 'failed');
    router.push('/login');
  },
  async loadTokenFromStorage({ commit }: any) {
    const token = await Storage.get({ key: 'access_token' });
    if (token && token.value) commit('saveToken', token.value);
  }
};

const mutations = {
  saveToken(state: { user: User }, payload: string) {
    const decodedPayload = jwtDecrypt(payload);
    const newData: User = {
      login: 'success',
      id: decodedPayload.sub,
      name: decodedPayload.sawonNm,
      access: {
        token: payload,
        exp: decodedPayload.exp
      },
      auth: decodedPayload.auth.split(',')
    };
    state.user = newData;
  },
  resetData(state: { user: User }) {
    state.user = {
      login: 'failed',
      id: '',
      name: '',
      access: {
        token: '',
        exp: ''
      },
      auth: ''
    };
  },
  saveLoginStatus(state: { user: User }, status: loginStatus) {
    state.user.login = status;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
