<template>
  <ion-page>
    <div id="container">
      <ion-card>
        <ion-img class="jeilhp" src="/assets/img/logo2.png"/>
        <ion-item>
          <ion-label position="fixed">아이디</ion-label>
          <ion-input v-model="user.id" required />
        </ion-item>
        <ion-item>
          <ion-label position="fixed">비밀번호</ion-label>
          <ion-input v-model="user.pw" type="password" required @keyup.enter="login" />
        </ion-item>
        <ion-item class="remember" lines="none">
          <ion-checkbox color="medium" v-model="rememberId">아이디저장</ion-checkbox>
          <ion-label>아이디 저장</ion-label>
          <ion-label color ="primary" slot ="end" router-link="/forget" class="underline">비밀번호찾기</ion-label>
        </ion-item>
        <ion-item class="remember" lines="none">
        <ion-button @click="login" color="medium">로그인</ion-button>
        <ion-button router-link="/terms" color="medium" fill="outline">회원가입</ion-button> 
      </ion-item>
      </ion-card>
    </div>
    <ion-footer class="ion-no-border">
      <p class="version">v0.0.1</p>
      <p class="copyright">Copyright@2023 Samil All rights reserved.</p>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonCard,
  IonCheckbox,
  IonFooter,
  IonImg,
  IonItem,
  IonInput,
  IonLabel,
  IonPage

} from '@ionic/vue';
import { defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { toast } from '@/utils/toast';
import { Storage } from '@capacitor/storage';


export default defineComponent({
  name: 'Login',
  components: {
    IonButton,
    IonCard,
    IonCheckbox,
    IonFooter,
    IonImg,
    IonItem,
    IonInput,
    IonLabel,
    IonPage
  },
  setup() {
    const store = useStore();
    const rememberId = ref(false);
    const user = reactive({
      id: '',
      pw: ''
    });

    Storage.get({ key: 'user_id' }).then(obj => {
      if (obj.value == null) return;

      user.id = obj.value;
      rememberId.value = true;
    });
    // const forget = async () => {
    //   const modal = await modalController.create({
    //     component: Forget,
    //     //Modal is name of the component to render inside ionic modal
    //   });
    //   return modal.present();
      
    // };
 

    async function login() {
      if (user.id && user.pw) {
        if (rememberId.value) {
          await Storage.set({
            key: 'user_id',
            value: user.id
          });
        } else {
          await Storage.remove({ key: 'user_id' });
        }

        await store.dispatch('auth/login', user);
      } else {
        toast('아이디 및 비밀번호를 입력하세요.');
      }
    }


    return {
      user,
      login,
      rememberId,
     
    };
  }
});
</script>

<style scoped>
ion-footer {
  text-align: center;
}
#container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
h1 {
  margin: 1em;
  color: white;
}
.jeilhp {
  max-width: 170px;
  margin: 0 auto;
}
ion-card {
  margin: 0 auto;
  width: 85%;
  max-width: 400px;
  padding: 1.5em 2.5em;
  min-height: 300px;
}
ion-button {
  width: 100%;
  margin-top: 0.5em;
  min-height: 40px;
}
ion-checkbox {
  position: relative;
}
ion-item {
  --padding-start: 0;
  --background-hover: none;
  --background-activated: none;
  margin: 1em 0;
}
.copyright {
  margin: 10px 0;
  font-size: 0.7em;
}
.version {
  margin: 0 0 5px 0;
}
.remember {
  margin: 0;
}
ion-checkbox {
  margin-right: 0.5em;
}
.underline {
  text-decoration: underline;
  margin: 0%;
      }
</style>